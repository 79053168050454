<template>
    <div id="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="box">
						<p>
							{{ $t('registry.generer_registre') }} <a href="#" @click.prevent="generateRegistreElevage">{{ $t('action.generer') }}</a>
						</p>
						<DateRangeV2
							:start.sync="duration.start"
							:end.sync="duration.end"
							@submit="init_component()"
							dateSelect
							:periodToShow="periodToShow"
							persistId="registry_history"
						/>
                        <CustomTable
                            id_table="registry_history"
                            :items="registries"
                            :busy.sync="table_busy"
                            :hasExportOption="false"
                            primaryKey="exporthistory_id"
                        />

                    </div>
                </div>
            </div>
        </div>

        <a v-if="document_dl_ready" ref="documentDownloadTag" href="#" hidden></a>

        <b-modal :modal-class="'modal-pdf'" v-if="document_show_ready" ref="modalViewDocumentRegistre" hide-footer>
            <template slot="modal-header" slot-scope="{ close }">
                <h2 class="mx-auto my-auto">{{ $t('ordonnance.document_visualisation') }}</h2>
                <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
            </template>

            <iframe
                id="pdf-iframe"
                class="modal-pdf"
                :src="show_src"
            ></iframe>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus';
	import Constants from 'Constants'
    import Documents from "@/mixins/Documents.js"
    import Navigation from "@/mixins/Navigation.js"
    import Registry from "@/mixins/Registry.js"
    import CustomTable from 'GroomyRoot/components/Table/CustomTable'
	import DateRangeV2 from 'GroomyRoot/components/Inputs/DateRangeV2'


    export default {
        name: "RegistreHistory",
        mixins: [Documents, Navigation, Registry],
        components: {
            CustomTable, DateRangeV2
        },
        data() {
            return {
                registries: null,
                table_busy: true,
                document_show_ready: false,
                document_dl_ready: false,
                show_src: '',
                events_tab: {
                    'RegistreHistory::SeedDocument': this.seedDocument,
                    'RegistreHistory::DownloadDocuments': this.downloadDocuments,
				},
				duration: {
					start: new Date(new Date().setMonth(new Date().getMonth() - 3)),
					end: new Date()
				},
				periodToShow: ['personalise', 'six_derniers_mois', 'trois_derniers_mois', 'annee_en_cours']
            }
        },
        mounted() {
			this.init_component()
        },
        methods: {
            async init_component() {
				this.registries = await this.getRegistries(this.duration.start.toDateInputValue(), this.duration.end.toDateInputValue())
				this.table_busy = false
            },
            async seedDocument(id) {
				if(this.asking_write_access) return

				if(!this.got_write_access) {
					this.interracting_data = id
					this.action_name = 'seedDocument'
					return this.askPermissionAndroid()
				}

                this.document_show_ready = false
                this.show_src = ''
                const content = await this.downloadRegistries(id)

                if(!content) this.failureToast("toast.info_get_file_failed")
                this.document_show_ready = true
				const blob = new Blob([content], {type: 'application/pdf'})
				
				if(Constants.IS_IOS_RUNNING || Constants.IS_AND_RUNNING) {
					const blobFile = new Blob([content])
					let reader = new FileReader()
					reader.readAsDataURL(blobFile)

					reader.onloadend = () => {
						const base64Cleanedup = this.formatBase64String(reader.result)

						if(Constants.IS_IOS_RUNNING) {
							window.nativeComm.fetchNative(res => {
								EventBus.$emit('TableAction::stopSpin')
							}, {
								base64: base64Cleanedup
							}, 'previewPdfFile')
						}
						else if(Constants.IS_AND_RUNNING) {
							window.nativeComm.fetchNative(res => {
								EventBus.$emit('TableAction::stopSpin')
							}, base64Cleanedup, 'previewPdfDocument')
						}
					}
				}
				else {
					const fakeUrl = window.URL.createObjectURL(blob)
					window.open(fakeUrl, '_blank')
				}
            },
            async downloadDocuments(ids) {
				if(this.asking_write_access) return

				if(!this.got_write_access) {
					this.interracting_data = ids
					this.action_name = 'downloadDocuments'
					return this.askPermissionAndroid()
				}

                const content = await this.downloadRegistries(ids)
                this.document_dl_ready = false

                if(!content) {
                    EventBus.$emit('TableAction::stopSpin')
                    this.failureToast("toast.info_get_files_failed")
				}
				
				this.document_dl_ready = true
				const blobFile = new Blob([content])
				let fileName = "Registres.zip"

				// Si un seul fichier, on prend son nom
				const oneSelected = (ids.split(',')).length === 1
				if(oneSelected) {
					const fileRegistry = this.registries.find(reg => reg.exporthistory_id == ids)
					fileName = fileRegistry.exporthistory_filename
				}

				if(Constants.IS_IOS_RUNNING || Constants.IS_AND_RUNNING) {
					let reader = new FileReader()
					reader.readAsDataURL(blobFile) // tester avec content directement, pour IOS

					reader.onloadend = () => {
						const base64Cleanedup = this.formatBase64String(reader.result)

						if(Constants.IS_IOS_RUNNING) {
							window.nativeComm.fetchNative(success => {
								this.successSaveIos(oneSelected, success)
							}, {
								filename: fileName,
								base64: base64Cleanedup
							}, 'writePdfFile')
						}
						else if(Constants.IS_AND_RUNNING) {
							const params = JSON.stringify({
								filename: fileName,
								base64: base64Cleanedup
							})

							window.nativeComm.fetchNative(status => {
								this.successSaveAnd(oneSelected, status)
							}, params, 'downloadDocuments')
						}
					}
				}
				else {
					// On attend que la balise <a> soit montée et prête
					Vue.nextTick(() => {
					    const fileURL = window.URL.createObjectURL(new Blob([content]))
					    const fileLink = this.$refs.documentDownloadTag
					    const fileExtension = content.type.search('zip') > -1 ? 'zip' : 'pdf'

					    fileLink.href = fileURL
					    fileLink.setAttribute('download', 'registre_elevage.'+fileExtension)

					    fileLink.click()
					    EventBus.$emit('TableAction::stopSpin')
					})
				}
			},

			async generateRegistreElevage() {
				await this.callGeneratedRegistreElevage()
				this.successToast("toast.registry_generated")
			},
			successSaveIos(oneSelected, success) {
				EventBus.$emit('TableAction::stopSpin')

				if(!success) {
					return this.failureToast('registry.save_ios_failure')
				}

				if(oneSelected) {
					this.successToast('registry.save_one_ios_success')
				}
				else {
					this.successToast('registry.save_multi_ios_success')
				}
			},

			successSaveAnd(oneSelected, status) {
				EventBus.$emit('TableAction::stopSpin')

				switch(status) {
					case "unauthorized":
						this.failureToast('registry.ask_and_doc_access_failure')
						break
					case "error": 
						this.failureToast('registry.save_and_failure')
						break
					case "success":
						if(oneSelected) {
							this.successToast('registry.save_one_and_success')
						}
						else {
							this.successToast('registry.save_multi_and_success')
						}
						break
				}
			}
		}
    }
</script>
