import Constants from '../../config.local.js'

var RegistryMixin = {
	methods: {
        /* Retourne tous les documents enregistrés, et liés à un cheval valide */
        getRegistries: async function(start_date, end_date) {
            const url = Constants.REGISTRY_LIST_URL + "?licence_key=" + Constants.USER_LICENCE_KEY+"&start_date="+start_date+"&end_date="+end_date
            const result = await this.$request.request_get_api("RegistryMixin::getRegistries", url)
            .catch(e => {
                console.error("RegistryMixin::getRegistries => Error:", e)
                return null
            })

            return result ? result.retour : []
        },

        downloadRegistries: async function(reg_ids) {
            const url = this.constructRoute(Constants.REGISTRY_DOWNLOAD_URL, {
                reg_ids: reg_ids
            }) + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_get_file_api("RegistryMixin::downloadRegistries", url)
            .catch(e => {
                console.error("RegistryMixin::getRegistries => Error:", e)
                return null
            })
        }
    }
}

export default RegistryMixin
